body {
  background-color: #242424 !important;
  font-family: 'Gotham Medium, Arial';
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-color: #cccccc !important;
}

.staff {
  border: 1px solid #e6e6e6;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(230, 230, 230);
  border-top: 1px solid transparent;
  background: #fff;
  position: relative;
  margin: 5px;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #04499e;
  border-color: #04499e;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #04499e;
  border-color: #04499e;
}

.btn-outline-primary {
  color: #959595;
  border-color: #04499e;
}

.properties .text span.status {
  position: initial;
}

.properties .info {
  display: flex;
  justify-content: space-between; /* Distribuye los elementos con espacio entre ellos */
  align-items: center; /* Alinea los elementos verticalmente al centro */
  width: 100%; /* Asegura que ocupe el 100% del espacio disponible */
  position: initial;
  margin-top: -50px;
}

.properties .reserva {
  display: flex;
  justify-content: space-between; /* Distribuye los elementos con espacio entre ellos */
  align-items: center; /* Alinea los elementos verticalmente al centro */
  width: 100%; /* Asegura que ocupe el 100% del espacio disponible */
  position: initial;
  background: #f52c24;
}


.properties .info .text span.status.sale {
  background: #04499e;
}

.properties .text span.status.type {
  background: #f52c24;
}

.properties .text h3 a {
  color: #04499e;
}

.properties .text .two .price {
  font-weight: 400;
  font-size: 16px;
  color: #f52c24;
}

a:hover {
  text-decoration: none;
  color: #04499e;
}

a {
  transition: 0.3s all ease;
  color: #04499e;
}

@media (max-width: 767.98px) {
  .img-2.prop {
    height: 300px;
    margin-bottom: 0px;
  }
}

.ftco-footer {
  background: #242424 !important;
}

.ftco-navbar-light {
  background: #242424 !important;
}

.top {
  background: transparent !important;
  background-color: transparent !important;
}

.icon-whatsapp {
  color: #41e15e;
}

.icon-instagram {
  color: #d30251;
}

.icon-facebook {
  color: #0266f7;
}

.icon-twitter {
  color: #1e97e8;
}
.img-agent {
  border-radius: 100% !important; /* Esto hace que la imagen sea circular */
  object-fit: cover !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  margin-top: 5px;
}
.agent-name {
  font-style: inherit;
  font-weight: bold;
  margin-left: 20px;
  font-size: 20px;
  color: #060202;
}

.avatar {
  max-height: 70px;
  max-width: 70px;
  border-radius: 100% !important; /* Esto hace que la imagen sea circular */
  object-fit: cover !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.img-props {
 /* border-top-left-radius: 15px; /* Redondea la esquina superior izquierda */
 /* border-top-right-radius: 15px; /* Redondea la esquina superior derecha */
  object-fit: cover; /* Asegura que la imagen se ajuste correctamente */
}

.price {
  color: #214b9e;
  font-size: 24px;
  font-style: inherit;
  font-weight: bold;
}
.property-description {
  color: #214b9e !important;
}

body {
  font-family: Arial;
}

.bg-white {
  background-color: #fff !important;
}

.font-rojo {
  color: #f52c24;
}

.font-azul {
  color: #04499e;
}

.font-azul-sec {
  color: #636466;
}

.background-azul {
  background-color: #04499e !important;
}

.background-azul:hover {
  color: #cccccc !important;
}

.background-whastapp {
  color: #25d366 !important;
}

#scrollToTopBtn {
  background: rgba(4, 73, 158, 0.59);
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white; /* Color del texto */
  padding: 5px 15px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  z-index: 100; /* Asegura que esté por encima de otros elementos */
}

#scrollToTopBtn:hover {
  background-color: #0056b3; /* Cambia el color al pasar el mouse */
}

#ftco-nav {
  background: #242424;
}

.nav-link {
  color: #cccccc !important;
  font-size: 20px !important;
  border: none !important;
}

.nav-link:hover {
  color: #ffffff !important;
  border-bottom: white 2px solid !important;
}

.escribinos {
  color: white;
  font-size: 15px;
  padding-left: 10px;
}

.collapse.navbar-collapse.show {
  margin-top: 20px;
}

/*
-- rojo : #f52c24
-- azul : #04499e
*/

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}


.ftco-section {
  top: -6rem !important;
}


.video-container-intro {
  position: relative;
  top: -6rem;
  /* max-width: 1920px; */
  max-height: 800px !important;
}

/* .iframe-intro {
  width: 100%;
  height: 100%;
} */

.overlay-intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Color semitransparente */
  z-index: 2; /* Asegura que esté por encima del iframe */
}

.corner-box {
  position: absolute;
  right: 10px;
  width: 100px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.9); /* Color blanco semitransparente */
  border-radius: 0px;
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Sombra para un mejor diseño */
  z-index: 3; /* Asegura que esté por encima del overlay */
}

.logo-remax {
  background-color: rgba(0, 0, 0, 0.31);
  max-width: 400px;
  border: 0;
}

/* .video-height {
  height: 100%;
} */

/* ===========================
   Responsive Design
   =========================== */

/* Para pantallas grandes (monitores de PC) */
@media (min-width: 1024px) {


  .logo-remax {
    max-width: 300px;
    margin-top: -4px;
  }

  .video-height {
    height: 100%;
  }
}

/* Para pantallas medianas (tablets) */
@media (min-width: 768px) and (max-width: 1023px) {


  .logo-remax {
    max-width: 250px;
    margin-top: -5px;
  }

  .video-container-intro {
    position: relative;
    top: -6rem;
    width: 100%;
    height: 100%;
  }
}

/* Para pantallas pequeñas (celulares) */
@media (max-width: 767px) {

  .logo-remax {
    max-width: 200px;
    margin-top: 23px !important;
  }

  .d-flex.justify-content-between.align-items-start.logo {
    margin-top: -25px !important;
  }

  .video-container-intro {
    position: relative;
    top: -3rem;
  }

  .ftco-section {
    top: -3rem !important;
  }


}

/* HOME */

/* Fondo del section */
.video-container-intro {
  position: relative;
  background: url("../public/img/intro.jpg") 50% 50%;
  background-size: cover;
  transition: opacity 2s ease; /* Transición suave de opacidad */
}

.background-visible {
  opacity: 1; /* Fondo completamente visible */
}

.background-hidden {
  opacity: 0; /* Fondo invisible */
}

/* Estilos del iframe */
.iframe-intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: black;
  transition: opacity 2s ease; /* Transición suave para el iframe */
  opacity: 0; /* Oculto inicialmente */
}

.iframe-visible {
  opacity: 1; /* Visible cuando el fondo desaparece */
}

.iframe-hidden {
  opacity: 0 !important; /* Mantener oculto hasta que se active */
}

.animated-text {
  position: absolute;
  left: 0;
  transform: translateX(-100%); /* Fuera de la pantalla inicialmente */
  opacity: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  transition: opacity 2s ease, transform 2s ease; /* Transiciones suaves */
}

.slide-in {
  transform: translateX(calc(50vw - 50%)); /* Mover al centro de la pantalla */
  opacity: 1;
}

/* SUMATE */
.sum-tablet {
  padding-bottom: 15px;
}


/* VIDEO REMAX */
.iframMarginMobile {
  margin: 2rem 0;
}

.iframMarginPC {
  margin: 6rem 0;
}